import axios from 'axios';

import { BadgeBg } from 'components/base/Badge';
import { MapLayer } from 'data/capas/capas';
import { User } from 'data/users';
import { apiUrl } from 'helpers/utils';
//import { Member, members } from 'data/users';

export interface Status {
  ongoing: number;
  critical: number;
  inactive: number;
  completed: number;
}

export interface Punto {
  id: number;
  name: string;
  nombrepunto: string;
  start: string;
  deadline: string;
  calculation?: {
    amount: string;
    label: string;
  }; 
  //assigness: Member[];
  progress: string
  task: number;
  statusProgress: Status;
  status: {
    label: string;
    type: BadgeBg;
  };
  bg: string;
  budget: number;
}

export interface CategoryRoot {
  categoryTitle: string;
  categoryDesc: string;
}

export interface ContentData {
  contentDataType: string;
  contentDataRowTitle: string;
  contentDataContent: string;
  contentFileNames: string[];
}

export interface MapMarker {
  _id: string;
  markerOwner: User;
  markerName: string;
  markerDescription: string;
  markerLayer: MapLayer;
  markerAddress: string;
  markerLat: number;
  markerLon: number;
  markerStatus: string;
  markerInsertDate: Date;
  markerExpireDate: Date;
  markerIconDesign: string;
  publicAsset: boolean;
  markerCategory: [CategoryRoot];
  markerData: [ContentData];
  markerTinyMce: string;
}

export const fetchMarkers = async (): Promise<MapMarker[]> => {
  try {
    const response = await axios.get(apiUrl + '/api/marker/list', { withCredentials: true });
    
    return response.data.content;
  } catch (error) {
    console.error('Error fetching markers:', error);
    return [];
  }
};

export const fetchMarkersByBounds = async (bounds: any, signal: AbortSignal): Promise<MapMarker[]> => {
  try {
    const response = await axios.post(apiUrl + '/api/marker/listbounds', { bounds }, {
      withCredentials: true,
      signal: signal
    });
    
    return response.data.content;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled, returning zero markers for bounds ' + JSON.stringify(bounds), error.message);
    } else {
      console.error('Error fetching markers:', error);
    }
    return [];
  }
};

export const fetchPublicMarkers = async (): Promise<MapMarker[]> => {
  try {
    const response = await axios.get(apiUrl + '/api/public/marker/list', { withCredentials: true });
    
    return response.data.content;
  } catch (error) {
    console.error('Error fetching layers:', error);
    return [];
  }
};
