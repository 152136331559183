import { useState } from 'react'
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import axios from 'axios';

import { apiUrl, isAuthenticated } from "helpers/utils";
import { Modal } from 'react-bootstrap';
import CheckmarkAnimation from 'pages/apps/poligonos/modal_anim/CheckmarkAnimation';
import CrossAnimation from 'pages/apps/poligonos/modal_anim/CrossAnimation';

const SignInForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const navigate = useNavigate();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successModalMessage, setSuccessModalMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  if (isAuthenticated()) {
    //return <Navigate to="/"></Navigate>
  }

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const handleLoginClick = async (e: any) => {
    e.preventDefault();

    axios.defaults.withCredentials = true;

      const isEmail = /^\S+@\S+\.\S+$/.test(login);

      const requestBody = new URLSearchParams();
      
      if (isEmail) {
          requestBody.append('email', login);
      } else {
          requestBody.append('username', login);
      }

      requestBody.append('password', password);
    
      const options = {
        method: 'POST',
        url: apiUrl + '/api/user/signin',
        data: requestBody,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };
      
      axios.request(options)
        .then(function (response) {
          setSuccessModalMessage('Bienvenido al sistema')
          setShowSuccessModal(true);

          localStorage.setItem("apiToken", response.data.token);
        })
        .catch(function (error) {
          console.error(error);
          if (!error.response) {
            setErrorModalMessage('No se ha recibido respuesta del servidor')
            setShowErrorModal(true);
            return;
          }
      
          const status = error.response.status;
          console.error("Estado de error:", status);
          if (status === 401) {
            setErrorModalMessage('Usuario no autorizado')
            setShowErrorModal(true);
            return;
          }
      
          setErrorModalMessage('Solicitud no autorizada por el servicio de XGIS.')
          setShowErrorModal(true);
        });
  };

  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
    setErrorModalMessage('');
  }

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    setSuccessModalMessage('');

    navigate("/");
  }

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">v{process.env.REACT_APP_VERSION}_{process.env.REACT_APP_TITLE}</h3>
        <p className="text-body-tertiary">Ingrese a su cuenta</p>
      </div>
      <div className="position-relative">
        <hr className="bg-body-secondary mt-5 mb-4" />
      </div>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="email">Correo o nombre de usuario</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="login"
            type="text"
            className="form-icon-input"
            placeholder="usuario o correo@dominio.com"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />
          <FontAwesomeIcon icon={faUser} className="text-body fs-9 form-icon" />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">Contraseña</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="password"
            type="password"
            className="form-icon-input"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FontAwesomeIcon icon={faKey} className="text-body fs-9 form-icon" />
        </div>
      </Form.Group>
      <Row className="flex-between-center mb-7">
        <Col xs="auto">
          <Form.Check type="checkbox" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember-me"
              id="remember-me"
              defaultChecked
            />
            <Form.Check.Label htmlFor="remember-me" className="mb-0">
              Recuérdame
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="auto">
          <Link
            to={`/pages/authentication/${layout}/forgot-password`}
            className="fs-9 fw-semibold"
          >
            ¿Ha olvidado su contraseña?
          </Link>
        </Col>
      </Row>
      <Button variant="primary" className="w-100 mb-3" onClick={ handleLoginClick }>
        Iniciar sesión
      </Button>

      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Éxito</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CheckmarkAnimation />
          {successModalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSuccessModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CrossAnimation />
          {errorModalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseErrorModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SignInForm;
